import React from 'react'
import $ from 'jquery'
import { I18nextContext, Link, useI18next } from 'gatsby-plugin-react-i18next'

//const {language, originalPath} = React.useContext(I18nextContext);


const isBrowser = typeof window !== "undefined"

export default function Script() {

    if(isBrowser){
        $(window).on('scroll', function(){
            if($(window).scrollTop() > 200){
                $('header').addClass("sticky-top");
            }
            else{
                $('header').removeClass("sticky-top");
            }
        });

        $(function(){
            $(".btn").on("click", function(){
                $(".btn").toggleClass("close-btn");
                $(".menu").toggleClass("open-menu");
                $(".mb-headernavwraper").toggleClass("fixed-header");
                $(".header-languagewraper,.privacyterms-module--privacyterms---TQJY .header-languagewraper").toggleClass("visible_block");
            });

            /*$('video').hover(function toggleControls() {
                if (this.hasAttribute("controls")) {
                    this.removeAttribute("controls");
                }
                 else {
                    this.setAttribute("controls", "controls");
                }
            });*/
            
        });

    }

    return (
      <></>  
    )
}
