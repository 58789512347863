import React from 'react'
import { Link } from 'gatsby'
import Facebook from "../assets/images/Facebook.png"
import linkedin from "../assets/images/Linkedin.png"
import Twitter from "../assets/images/Twitter.png"
import Facebookhover from "../assets/images/facebookhover.png"
import linkedinhover from "../assets/images/linkedinhover.png"
import Twitterhover from "../assets/images/twitterhover.png"
import footerlogo from "../assets/images/footerlogo.png"
import { Trans } from 'gatsby-plugin-react-i18next'
import { I18nextContext, useI18next } from 'gatsby-plugin-react-i18next'

export default function Footer() {

    const {language, originalPath} = React.useContext(I18nextContext);

    return (
        <footer className="footer-wraper">
            <div className="footer-wraped container-max-width">
                <div className="footer-wraper-top row">
                    <div className="left-block col-md-8">
                        <div className="subtitle-wraper">
							<span className="hightlight-block">
								<span className={(language==="en")?"active":""}>An English language learning app made specifically for native <span className="highlighted-text">Hindi & Tamil</span> speakers.</span>
								<span className={(language==="hi")?"active":""}>अंग्रेज़ी भाषा सीखने के लिए एप जो पैदाइशी <span className="highlighted-text">हिंदी और तामिल</span> भाषियों के लिए बनाई गई है</span>
								<span className={(language==="ta")?"active":""}>ஹிந்தி மற்றும் தமிழ் பேசுபவர்களுக்காக சிறப்பாகத் தயாரிக்கப்பட்ட ஆங்கிலம் கற்கும் ஆப்.</span>
							</span> 
                        </div>
                        <div className="downloadlinkwraper witharrow">
                        <Link className="nav-link" aria-current="page" to="https://play.google.com/store/apps/details?id=com.talkingyak.app" target="_blank"><span className="link-text"><Trans>Download Now</Trans></span><span className="link__icon"></span></Link>
                        </div>
                    </div>
                    <div className="right-block col-md-4">
                        <div className="footer-logowraper">
                            <Link to="/" className="footer-logo"><img src={footerlogo} alt="Logo" /></Link>
                        </div>
                    </div>
                </div>
                <div className="footer-wraper-bottom row">
                    <div className="left-block col-md-8">
                        <div className="footer-links-cpwraper">
                            <ul className="links-wraper">
                                <li><Link className="nav-link" aria-current="page" to="/our-story"><Trans>OUR STORY</Trans></Link></li><span>|</span><li><Link className="nav-link" aria-current="page" to="/contact"><Trans>CONTACT</Trans></Link></li><span>|</span><li><Link className="nav-link" aria-current="page" to="/privacy-policy"><Trans>PRIVACY & TERMS</Trans></Link></li>
                            </ul>
                            <div className="cp-wraper">
                                <Trans>© 2021, Talking Yak, All Rights Reserved | Designed by Lucid Design</Trans>
                            </div>
                        </div>
                    </div>
                    <div className="right-block col-md-4">
                        <ul className="social-linkswraper">
                            <li><Link className="social-links" to="#"><img className="facebook-default" src={Facebook} alt="social" /><img className="facebook-hover d-none" src={Facebookhover} alt="social" /></Link></li><li><Link className="social-links" to="#"><img className="linked-default" src={linkedin} alt="social" /><img className="linked-hover d-none" src={linkedinhover} alt="social" /></Link></li><li><Link className="social-links" to="#"><img className="twitter-default" src={Twitter} alt="social" /><img className="twitter-hover d-none" src={Twitterhover} alt="social" /></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
