import { I18nextContext, Link, useI18next } from 'gatsby-plugin-react-i18next'
import React from 'react'
import logo from "../assets/images/logo.png"
import TalkingYak_ScrollLogo from "../assets/images/TalkingYak_ScrollLogo.png"
import Facebook from "../assets/images/Facebook.png"
import linkedin from "../assets/images/Linkedin.png"
import Twitter from "../assets/images/Twitter.png"
import { Trans } from 'gatsby-plugin-react-i18next'

//import $ from "jquery"

export default function Header() {
    const {language, originalPath} = React.useContext(I18nextContext)
    const {changeLanguage} = useI18next();

    /*const mbBtnClick = () => {
        alert("testttt");
        $('header').addClass('abi');
    }*/ 

    return (
        <header className="header-wraper">
            <div className="header-wraper-inner header-wraper-desktop">
                <div className="header-wraper-inner-wraped container-max-width">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="header-sectionwraped row">
                            <div className="left-block col-md-6">
                                <Link to="/" className="navbar-brand logo-wraper"><img className="white-coloredlogo" src={logo} alt="Logo" /><img className="blue-coloredlogo d-none" src={TalkingYak_ScrollLogo} alt="Logo" /></Link>
                                <Link to="/" className="navbar-brand logo-wraper logo-wraper-desktop d-none"><img src={TalkingYak_ScrollLogo} alt="Logo" /></Link>
                                <div className="header-languagewraper">
                                    <ul>
                                        {/*languages.map((lng) => (
                                        <li key={lng}>
                                            <Link to={originalPath} language={lng}>
                                              {lng}
                                            </Link>
                                          </li>        
                                        ))*/}
                                        <li className={(language==="en")?"active":""}><Link className="nav-link" aria-current="page" to="#" onClick={(e)=>{e.preventDefault();changeLanguage("en");}}>ENG</Link></li><span>|</span>
                                        <li className={(language==="hi")?"active":""}><Link className="nav-link" aria-current="page" to="#" onClick={(e)=>{e.preventDefault();changeLanguage("hi");}}>हिन्दी</Link></li><span>|</span>
                                        <li className={(language==="ta")?"active":""}><Link className="nav-link" aria-current="page" to="#" onClick={(e)=>{e.preventDefault();changeLanguage("ta");}}>தமிழ்</Link></li>
                                    </ul>
                                </div>
                                <button className="navbar-toggler mobile-hamburger" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="right-block col-md-6">
                                <div className="collapse navbar-collapse navlists-wraper" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className={(originalPath === '/our-story/')?'nav-item active':'nav-item'}>
                                            <Link className="nav-link" aria-current="page" to="/our-story"><Trans>OUR STORY</Trans></Link>
                                        </li>
                                        <li className={(originalPath === '/contact/')?'nav-item active':'nav-item'}>
                                        <Link className="nav-link" aria-current="page" to="/contact"><Trans>CONTACT</Trans></Link>
                                        </li>
                                        <li className="nav-item partners-loginlink">
                                        <a className="nav-link" aria-current="page" href="https://reports-enterprise.talkingyak.net/login" target="_blank" rel="noopener noreferrer"><Trans>PARTNER LOGIN</Trans></a>
                                        </li>
                                        <li className="nav-item downloadlinkwraper">
                                        <a href="https://play.google.com/store/apps/details?id=com.talkingyak.app" className="nav-link active" aria-current="page" target="_blank"><Trans>Download Now</Trans></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            
            <div className="mobile-menumain-wraper">
                <div className="mb-headernavwraper">
                    <div className="mb-headernavwraped">                       
                        <div className="md-logowraper">
                            <Link to="/" className="navbar-brand logo-wraper"><img className="white-coloredlogo" src={logo} alt="Logo" /><img className="blue-coloredlogo d-none" src={TalkingYak_ScrollLogo} alt="Logo" /></Link>
                            <Link to="/" className="navbar-brand logo-wraper logo-wraper-mobile d-none"><img src={TalkingYak_ScrollLogo} alt="Logo" /></Link>
                        </div>
                        <div className="btn">
                            <span className="bar1"></span>
                            <span className="bar2"></span>
                            <span className="bar3"></span>
                        </div>
                        <div className="header-languagewraper mb">
                            <ul>
                                <li className={(language==="en")?"active":""}><Link className="nav-link" aria-current="page" to="#" onClick={(e)=>{e.preventDefault();changeLanguage("en");}}>ENG</Link></li><span>|</span>
                                <li className={(language==="hi")?"active":""}><Link className="nav-link" aria-current="page" to="#" onClick={(e)=>{e.preventDefault();changeLanguage("hi");}}>हिन्दी</Link></li><span>|</span>
                                <li className={(language==="ta")?"active":""}><Link className="nav-link" aria-current="page" to="#" onClick={(e)=>{e.preventDefault();changeLanguage("ta");}}>தமிழ்</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="menu mobile-overlayitemswraper">
                    <ul className="navbar-nav">
                        <li className={(originalPath === '/')?'nav-item active':'nav-item'}>
                            <Link className="nav-link" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className={(originalPath === '/our-story/')?'nav-item active':'nav-item'}>
                            <Link className="nav-link" aria-current="page" to="/our-story">Our Story</Link>
                        </li>
                        <li className={(originalPath === '/contact/')?'nav-item active':'nav-item'}>
                        <Link className="nav-link" aria-current="page" to="/contact">Contact</Link>
                        </li>
                        <li className="nav-item partners-loginlink-mb">
                            <a className="nav-link" aria-current="page" href="https://reports-enterprise.talkingyak.net/login" target="_blank" rel="noopener noreferrer">Partner Login</a>
                        </li>
                    </ul>
                    <div className="downloadlinkwraper witharrow">
                        <Link className="nav-link" aria-current="page" to="#"><span className="link-text">Download Now</span><span className="link__icon"></span></Link>
                    </div>
                    <ul className="social-linkswraper">
                        <li><Link className="social-links" to="#"><img src={Facebook} alt="social" /></Link></li><li><Link className="social-links" to="#"><img src={linkedin} alt="social" /></Link></li><li><Link className="social-links" to="#"><img src={Twitter} alt="social" /></Link></li>
                    </ul>
                   <div className="cp-wraper-privacylinkwraper">
                        <div className="privacy-linkwraped"><Link className="nav-link" aria-current="page" to="/privacy-terms">PRIVACY & TERMS</Link></div>
                        <div className="copy-writewraped">© 2021, Talking Yak, All Rights Reserved | Designed by Lucid Design</div>
                    </div>
                </div>
            </div>

        </header>
    )
}
