import React from 'react'
import Header from './Header';
import Footer from './Footer';
import '../assets/css/styles.css'
import { I18nextContext, Link, useI18next } from 'gatsby-plugin-react-i18next'
/*import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';*/
import Script from './Script';

export default function Layout({children}) {

    const {language, originalPath} = React.useContext(I18nextContext);
    
    return (
        <div class="page-wrapper container-fluid">
            <Header />
            <main className={language}>
                {children}
            </main>
            <Footer />
            <Script />
            {/*<Helmet>
                <script type="text/javascript" src={withPrefix('main.js')}></script>
            </Helmet>*/}
        </div>
    )
}
